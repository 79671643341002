import React from "react";
import BGImage from "./assets/hero_bg3.svg"; 
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBrain, faCalendarAlt, faEnvelope, faPen, faSearch, faRocket, faImage, faVideo, faBlog, faClosedCaptioning, faMicrophone, faGlobe } from '@fortawesome/free-solid-svg-icons';

export const PastExtraSection = ({ showForm, setShowForm }) => {
 

  return (
    <div 
      className="flex flex-col items-center justify-center min-h-screen px-4 py-10 text-white"
      style={{ backgroundImage: `url(${BGImage})`, backgroundSize: '105% 105%', backgroundPosition: 'center' }}
    >
      {/* Header */}
      <div className="text-center mb-10">
        <h2 className="text-4xl md:text-5xl font-bold mb-4">Other Services</h2>
        <div className="text-lg md:text-xl text-center   w-full text-gray-300" style={{ textAlign: 'center' }}>+
          We use AI to provide additional services for efficient and cost-effective content creation.
        </div>
      </div>

      {/* Video Stack */}
      <div className="flex flex-wrap justify-center max-w-6xl w-full">
        {/* Extra Services Plan Card */}
        <div className="relative flex flex-col items-center bg-white bg-opacity-10 p-8 rounded-lg border border-green-500 w-full md:w-1/3 flex-1 min-h-[500px]">
          <h3 className="text-lg font-semibold text-green-400 mb-4">  Enhance your content strategy with our exclusive AI-driven services tailored to your needs.</h3> 
          <div className="text-gray-300 mb-6 mt-4 text-center w-full pl-4">
          
          </div>
          <ul className="list-none text-left text-gray-300 space-y-2 sm:text-left">
            <li>
              <FontAwesomeIcon icon={faBrain} /> <strong>AI-Driven Content Strategy</strong>: AI-crafted strategy to engage your audience and boost brand impact.
            </li>
            <li>
              <FontAwesomeIcon icon={faCalendarAlt} /> <strong>AI-Powered Social Media Scheduling</strong>: Automate social posts for peak engagement, tailored to your audience.
            </li>
            <li>
              <FontAwesomeIcon icon={faEnvelope} /> <strong>Automated Email Campaigns</strong>: Personalized emails that drive engagement and conversions, effortlessly.
            </li>
            <li>
              <FontAwesomeIcon icon={faPen} /> <strong>Intelligent Copywriting</strong>: On-brand, persuasive copy for product descriptions, ads, and social media.
            </li>
            <li>
              <FontAwesomeIcon icon={faSearch} /> <strong>AI-Powered SEO Optimization</strong>: Boost search rankings with AI-driven keyword and content strategies.
            </li>
            <li>
              <FontAwesomeIcon icon={faRocket} /> <strong>GEO: Generative Engine Optimization</strong>: Enhance discoverability with AI-powered, SEO-optimized content.
            </li>
            <li>
              <FontAwesomeIcon icon={faImage} /> <strong>Custom Image Models</strong>: Unique, on-brand visuals without the need for a full design team.
            </li>
            <li>
              <FontAwesomeIcon icon={faVideo} /> <strong>360° Product Demos</strong>: Create immersive 360° product demos at a fraction of traditional costs.
            </li>
            <li>
              <FontAwesomeIcon icon={faBlog} /> <strong>Automated Blog Writing</strong>: Generate high-quality, SEO-friendly blog posts that reflect your brand.
            </li>
            <li>
              <FontAwesomeIcon icon={faClosedCaptioning} /> <strong>AI Video Transcription & Subtitling</strong>: Accurate transcriptions and subtitles for accessible, engaging videos.
            </li>
            <li>
              <FontAwesomeIcon icon={faMicrophone} /> <strong>AI Voiceover Generation</strong>: Professional voiceovers for videos and ads, without voice talent costs.
            </li>
            <li>
              <FontAwesomeIcon icon={faGlobe} /> <strong>AI Content Translation & Localization</strong>: Publish content in multiple languages with effortless AI localization.
            </li>
          </ul>
          <div className="flex-grow"></div> {/* Spacer to push button to bottom */}
          <button className="w-64 mt-8 mb-4 py-3 rounded-full bg-green-500 hover:bg-green-600 transition " 
            onClick={() => {
              window.scrollTo({ 
                top: document.body.scrollHeight, 
                behavior: 'smooth' 
              }); 
            }}>
           Lets Talk
          </button>
        </div>
      </div>
    </div>
  );
};