import React, { useEffect, useRef } from "react";
import backgroundImage from "./assets/hero_bg3.svg";
import logoImage from "./assets/logo.jpg";
import GetStartedForm from "./GetStartedForm";

// Carousel logos

import elevenLabs from "./assets/11labs.png";
import qolaba from "./assets/qolaba.svg";
import anthropic from "./assets/anthropic.png";
import leonardo from "./assets/leonardo.png";
import midjourney from "./assets/midjourney.png";
import openai from "./assets/openai.png";
import luma from "./assets/luma.png";

export const LandingSection = ({ showForm, setShowForm }) => {
  const carouselRef = useRef(null);

  // Auto-scroll the carousel
  useEffect(() => {
    let scrollInterval;
    const startAutoScroll = () => {
      scrollInterval = setInterval(() => {
        if (carouselRef.current) {
          const { scrollLeft, scrollWidth, clientWidth } = carouselRef.current;
          if (scrollLeft + clientWidth >= scrollWidth) {
            carouselRef.current.scrollLeft = 0; // Reset to start
          } else {
            carouselRef.current.scrollLeft += 1;
          }
        }
      }, 20);
    };

    startAutoScroll();

    return () => {
      clearInterval(scrollInterval);
    };
  }, []);

  return (
    <div
      className="relative min-h-screen bg-cover flex flex-col justify-between text-white"
      style={{
        backgroundImage: `url(${backgroundImage})`,
        backgroundSize: '105% 125%',
        backgroundPosition: 'center center',
    
      }}
    >
      {/* Header */}
      <header className="flex flex-col items-center py-5">
        {/* Logo */}
        <div className="flex items-center gap-2 mb-4">
          <img src={logoImage} alt="Qarrom Logo" className="w-9 h-9" />
          <div className="font-polysans text-2xl font-semibold">Qarrom AI</div>
        </div>
        {/* Centered Navigation */}
        <nav className="flex hidden gap-5">
          {['Benefits', 'Past work', 'How it works', 'Our Pricing', 'Our FAQ', 'Login Account'].map((item) => (
            <button
              key={item}
              className="text-white px-4 py-1 rounded-full transition duration-200 text-lg"
              style={{
                background: `rgba(255, 255, 255, 0.1)`,
                border: '1px solid rgba(255, 255, 255, 0.2)'
              }}
              onMouseEnter={(e) => e.currentTarget.style.border = '1px solid white'}
              onMouseLeave={(e) => e.currentTarget.style.border = '1px solid rgba(255, 255, 255, 0.2)'}
            >
              {item}
            </button>
          ))}
        </nav>
      </header>

      {/* Main Hero Content */}
      <div className="flex flex-col items-center py-16 px-6 text-center">
        <h1 className="text-5xl md:text-6xl lg:text-7xl font-bold mb-4">
          Transform Your Content with AI Power
        </h1>
        <h2 className="text-2xl md:text-3xl lg:text-4xl mb-6">
          Introducing <span className="font-semibold text-orange-400">QCAAS</span> - Quick Content as a Service
        </h2>
        <p
          className="text-lg md:text-xl lg:text-2xl leading-relaxed mb-8 max-w-4xl mx-auto"
        >
          Delivering high-quality, scroll-stopping content <span className="font-bold text-orange-400">3x</span> cheaper and <span className="font-bold text-orange-400">2x</span> faster than traditional agencies, Qarrom AI empowers D2C brands to dominate the market with cutting-edge AI.
        </p>
        <div className="flex gap-4">
          <button
            className="w-48 h-12 rounded-full font-semibold shadow-lg bg-orange-500 transition duration-300 hover:bg-orange-600"
            onClick={() => {
              window.scrollTo({ 
                top: document.body.scrollHeight, 
                behavior: 'smooth' 
              }); 
            }}>
            Get Started
          </button>
        </div>
      </div>

      {/* Conditionally render the GetStartedForm */}
      {showForm && <GetStartedForm onClose={() => setShowForm(false)} />}

      <div className="flex flex-col items-center justify-center text-gray-100">
        <span className="powered-by-animation">
          {"Powered ".split('').map((char, index) => (
            <span key={index}>{char}</span>
          ))}  <span key="43">b</span><span key="45">y</span>
        </span>
      </div>

      {/* Carousel Section */}
      <div
        className="flex items-center justify-center py-4 overflow-x-auto"
        ref={carouselRef}
        style={{
          whiteSpace: 'nowrap',
          overflowX: 'auto',
          scrollbarWidth: 'none', // For Firefox
          msOverflowStyle: 'none' // For Internet Explorer and Edge
        }}
      >
        {[
          { src: anthropic, name: "Anthropic" },
          { src: elevenLabs, name: "Eleven Labs" },
          { src: qolaba, name: "Qolaba" },
          { src: openai, name: "OpenAI" },
          { src: leonardo, name: "Leonardo" },
          { src: midjourney, name: "Midjourney" },
          { src: luma, name: "Luma" }
        ].map((logo, index) => (
          <div key={index} className="flex flex-col items-center mx-4">
            <img
              src={logo.src}
              alt={`Logo ${index + 1}`}
              className="w-24 h-24 object-contain"
              style={{ flexShrink: 0, minWidth: '6rem', minHeight: '6rem' }} // Prevent shrinking
            />
          </div>
        ))}
      </div>
    </div>
  );
};
