import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

const ContactForm = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    Name: '',
    Email: '',
    Phone: '',
    CompanyWebsite: '',
    Message: ''
  });

  const [status, setStatus] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    setStatus('submitting');

    try {
      const response = await fetch('https://api.qolaba.ai/api/v1/qarrom/contactFrom', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });
      console.log(response, "- response")

      if (response.ok) {
        setStatus('success');
        setFormData({ 
          Name: '', 
          Email: '', 
          Phone: '', 
          CompanyWebsite: '',
          Message: ''
        });
        navigate("/thankyou")
      } else {
        setStatus('error');
      }
    } catch (error) {
      console.error('Submission error:', error);
      setStatus('error');
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  return (
<form 
  onSubmit={handleSubmit}
  className="max-w-2xl mx-auto p-8 border-4 border-white sm:border-black rounded-xl shadow-[8px_8px_0px_rgba(0,0,0,1)] space-y-6 text-black"
>
  <h2 className="text-3xl font-bold text-orange-500 text-center border-b-4 border-white sm:border-black pb-4">
    Contact Us
  </h2>

  <div className="space-y-4">
    <input
      type="text"
      name="Name"
      value={formData.Name}
      onChange={handleChange}
      placeholder="Name *"
      required
      aria-label="Name"
      className="w-full p-3 border-2 border-black rounded-lg focus:outline-none focus:ring-2 focus:ring-black text-black placeholder-gray-600"
    />

    <input
      type="email"
      name="Email"
      value={formData.Email}
      onChange={handleChange}
      placeholder="Email *"
      required
      aria-label="Email"
      className="w-full p-3 border-2 border-black rounded-lg focus:outline-none focus:ring-2 focus:ring-black text-black placeholder-gray-600"
    />

    <input
      type="tel"
      name="Phone"
      value={formData.Phone}
      onChange={handleChange}
      placeholder="Phone (optional)"
      aria-label="Phone"
      className="w-full p-3 border-2 border-black rounded-lg focus:outline-none focus:ring-2 focus:ring-black text-black placeholder-gray-600"
    />

    <input
      type="url"
      name="CompanyWebsite"
      value={formData.CompanyWebsite}
      onChange={handleChange}
      placeholder="Company Website (optional)"
      aria-label="Company Website"
      className="w-full p-3 border-2 border-black rounded-lg focus:outline-none focus:ring-2 focus:ring-black text-black placeholder-gray-600"
    />

    <textarea
      name="Message"
      value={formData.Message}
      onChange={handleChange}
      placeholder="Your Message *"
      required
      aria-label="Message"
      rows="4"
      className="w-full p-3 border-2 border-black rounded-lg focus:outline-none focus:ring-2 focus:ring-black text-black placeholder-gray-600"
    />
  </div>

  <button
    type="submit"
    disabled={status === 'submitting'}
    className={`w-full py-4 text-lg font-bold text-white bg-black rounded-lg transition-all 
      ${status === 'submitting' 
        ? 'cursor-not-allowed opacity-50' 
        : 'hover:bg-white hover:text-black hover:border-2 hover:border-black'
      }`}
  >
    {status === 'submitting' ? 'Submitting...' : 'Send Message'}
  </button>

  {status === 'success' && (
    <p className="text-center text-green-600 font-bold">
      Form submitted successfully!
    </p>
  )}
  {status === 'error' && (
    <p className="text-center text-red-600 font-bold">
      An error occurred. Please try again.
    </p>
  )}
</form>

  );
};

export default ContactForm;