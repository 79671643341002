// Placeholder imports for the icons; replace these with actual image paths
import backgroundImage from "./assets/hero_bg3.svg"; // Background image

export const Thankyou = () => {

  return (
    <div
      className="relative min-h-screen bg-cover flex flex-col justify-between text-white p-10"
      style={{
        background: "inherit",
        // height: "100vh",
        backgroundImage: `url(${backgroundImage})`,
        backgroundSize: "105% 125%",
        backgroundPosition: "center center",
      }}
    >
      <div className="mt-10">
        <h1 className="text-center text-5xl">Thank You for Reaching Out!</h1>
        <p className="text-center text-lg mt-4">
          We have received your message and appreciate you contacting us. 
        </p>
        <p className="text-center text-lg">
          Our
          team will review your inquiry and get back to you shortly.
        </p>
      </div>
    </div>
  );
};
