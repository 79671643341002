 
// Placeholder imports for the icons; replace these with actual image paths
import affordableIcon from "./assets/affordable.png";
import monthlyRateIcon from "./assets/monthly.png";
import customTrainedIcon from "./assets/trained.png";
import fastExecutionIcon from "./assets/execution.png";
import realTimeIcon from "./assets/slack.png";
import fullStackIcon from "./assets/team.png";
import BGImage from "./assets/hero_bg3.svg"  
 
export const AgencySection = ({showForm, setShowForm}) => {  // State to manage form visibility

  return (
    <>
    <div 
      className="flex flex-col items-center justify-center min-h-screen px-4 py-8 md:py-16 text-white"
      style={{ backgroundImage: `url(${BGImage})`, backgroundSize: '105% 125%', backgroundPosition: 'centre' }}
    >
      {/* Header */}
      <div className="text-center mb-10">
        <h1 className="text-4xl md:text-5xl font-bold mb-4">Everything you've wanted in an agency</h1>
        <p className="text-lg md:text-xl text-center"> At Qarrom AI, we’re startup founders too. We created the AI agency we wished existed.</p>
      </div>

      {/* Features Grid */}
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 max-w-6xl w-full">
        {/* Feature Card */}
        <div className="flex flex-col items-start bg-white bg-opacity-10 p-6 rounded-lg shadow-lg hover:bg-opacity-20 transition duration-300">
          <img src={affordableIcon} alt="Super affordable" className="w-12 h-12 mb-4" />
          <h2 className="text-2xl font-semibold mb-2">Super Affordable</h2>
          <p className="text-left">Save 2-3x the cost compared to traditional agencies.</p>
        </div>

        <div className="flex flex-col items-start bg-white bg-opacity-10 p-6 rounded-lg shadow-lg hover:bg-opacity-20 transition duration-300">
          <img src={monthlyRateIcon} alt="Fixed, monthly rate" className="w-12 h-12 mb-4" />
          <h2 className="text-2xl font-semibold mb-2">Fixed, Monthly Rate</h2>
          <p className="text-left">No long-term commitments; pause or cancel anytime.</p>
        </div>
   
        <div className="flex flex-col items-start bg-white bg-opacity-10 p-6 rounded-lg shadow-lg hover:bg-opacity-20 transition duration-300">
          <img src={fullStackIcon} alt="Full-stack team" className="w-12 h-12 mb-4" />
          <h2 className="text-2xl font-semibold mb-2">Full-Stack Team</h2>
          <p className="text-left">Strategists, AI creators, designers, and reporting experts all in one team.</p>
        </div>
     

        <div className="flex flex-col items-start bg-white bg-opacity-10 p-6 rounded-lg shadow-lg hover:bg-opacity-20 transition duration-300">
          <img src={fastExecutionIcon} alt="Fast execution" className="w-12 h-12 mb-4" />
          <h2 className="text-2xl font-semibold mb-2">Fast Execution</h2>
          <p className="text-left">Receive designs within 2-3 business days, Monday to Friday.</p>
        </div>
        <div className="flex flex-col items-start bg-white bg-opacity-10 p-6 rounded-lg shadow-lg hover:bg-opacity-20 transition duration-300">
          <img src={customTrainedIcon} alt="AI custom trained" className="w-12 h-12 mb-4" />
          <h2 className="text-2xl font-semibold mb-2">AI Custom Trained</h2>
          <p className="text-left">Get proven, experienced AI models, trained just for you.</p>
        </div>
        <div className="flex flex-col items-start bg-white bg-opacity-10 p-6 rounded-lg shadow-lg hover:bg-opacity-20 transition duration-300">
          <img src={realTimeIcon} alt="Real-time reporting" className="w-12 h-12 mb-4" />
          <h2 className="text-2xl font-semibold mb-2">Real-Time Reporting</h2>
          <p className="text-left">Track performance of your content in real time.</p>
        </div>

      </div>

      {/* Button */}
      <div className="mt-10">
      <button
  className="w-48 h-12 rounded-full font-semibold shadow-lg bg-orange-500 transition duration-300 hover:bg-orange-600"
  onClick={() => {
    window.scrollTo({ 
      top: document.body.scrollHeight, 
      behavior: 'smooth' 
    }); 
  }}
>
  Lets Talk
</button>

</div>

    </div>  
 
    </>
  );
};
 