import React from "react";
import backgroundImage from "./assets/hero_bg3.svg";
import waveImage from "./assets/wave.png";
import verticalWaveImage from "./assets/vertical_wave.png";

export const HowItWorks = ({ showForm, setShowForm }) => {
  return (
    <div
      className="relative flex flex-col items-center justify-start min-h-screen px-4 py-10   text-white"
      style={{ backgroundImage: `url(${backgroundImage})`, backgroundSize: '100% 105%', backgroundPosition: 'centre' }}
    >
      {/* Header */}
      <div className="text-center mb-10">
        <h2 className="text-4xl md:text-5xl font-bold mt-8 mb-24 lg:mb-32   text-stroke-2 text-stroke-black">How we work</h2>
      
      </div>

      {/* Steps Section */}
      <div className="flex flex-col md:flex-row items-center justify-between gap-10 max-w-5xl w-full">
        
        {/* Step 1 */}
        <div className="flex flex-col items-center text-center">
          <div className="flex items-center justify-center w-16 h-16 bg-orange-500 rounded-full text-2xl font-bold mb-4">1</div>
          <h3 className="text-2xl font-semibold mb-2">Get Onboarded (Fast)</h3>
          <p className="text-gray-300">We start by understanding your goals, brand style, and content needs.</p>
        </div>

        {/* Wave Divider between Step 1 and Step 2 */}
        <div className="flex items-center justify-center">
          {/* Desktop Wave */}
          <img src={waveImage} alt="Wave Divider" className="hidden md:block w-32 object-contain" />
          {/* Mobile Vertical Wave */}
          <img src={verticalWaveImage} alt="Vertical Wave Divider" className="md:hidden h-24 object-contain" />
        </div>

        {/* Step 2 */}
        <div className="flex flex-col items-center text-center">
          <div className="flex items-center justify-center w-16 h-16 bg-blue-500 rounded-full text-2xl font-bold mb-4">2</div>
          <h3 className="text-2xl font-semibold mb-2">Fast Execution in 2-3 Days</h3>
          <p className="text-gray-300">Our QCAAS model ensures rapid delivery without compromising quality.</p>
        </div>

        {/* Wave Divider between Step 2 and Step 3 */}
        <div className="flex items-center justify-center">
          {/* Desktop Wave */}
          <img src={waveImage} alt="Wave Divider" className="hidden md:block w-32 object-contain" />
          {/* Mobile Vertical Wave */}
          <img src={verticalWaveImage} alt="Vertical Wave Divider" className="md:hidden h-24 object-contain" />
        </div>

        {/* Step 3 */}
        <div className="flex flex-col items-center text-center">
          <div className="flex items-center justify-center w-16 h-16 bg-orange-500 rounded-full text-2xl font-bold mb-4">3</div>
          <h3 className="text-2xl font-semibold mb-2">Iterate, Grow, Optimize</h3>
          <p className="text-gray-300">We refine and enhance your content to ensure it delivers maximum results.</p>
        </div>
      </div>
      <button className="w-64 mt-24 mb-4 py-3 rounded-full bg-green-500 hover:bg-green-600 transition"
          onClick={() => {
            window.scrollTo({ 
              top: document.body.scrollHeight, 
              behavior: 'smooth' 
            }); 
          }} >
           Lets Talk
          </button>
    </div>
  );
};
 
