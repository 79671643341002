import React, { useState, useRef, useEffect } from "react";
import backgroundImage from "./assets/hero_bg3.svg"; // Background image
// Carousel logos
import elevenLabs from "./assets/11labs.png";
import qolaba from "./assets/qolaba.svg";
import anthropic from "./assets/anthropic.png";
import leonardo from "./assets/leonardo.png";
import midjourney from "./assets/midjourney.png";
import openai from "./assets/openai.png";
import luma from "./assets/luma.png";
import ContactForm from './contact'

export const FooterSection = ({ showForm, setShowForm }) => {
  // const [formData, setFormData] = useState({
  //   Name: "",
  //   Email: "",
  //   Phone: "",
  //   CompanyWebsite: "",
  //   Message: "",
  // });

  // const [isSubmitting, setIsSubmitting] = useState(false);
  // const [submitSuccess, setSubmitSuccess] = useState(false);

  const carouselRef = useRef(null);

  // Auto-scroll the carousel
  useEffect(() => {
    let scrollInterval;
    const startAutoScroll = () => {
      scrollInterval = setInterval(() => {
        if (carouselRef.current) {
          const { scrollLeft, scrollWidth, clientWidth } = carouselRef.current;
          if (scrollLeft + clientWidth >= scrollWidth) {
            carouselRef.current.scrollLeft = 0; // Reset to start
          } else {
            carouselRef.current.scrollLeft += 1;
          }
        }
      }, 20);
    };

    startAutoScroll();

    return () => {
      clearInterval(scrollInterval);
    };
  }, []);

  const carouselImages = [
    { src: anthropic, alt: "Anthropic Logo" },
    { src: elevenLabs, alt: "Eleven Labs Logo" },
    { src: qolaba, alt: "Qolaba Logo" },
    { src: openai, alt: "OpenAI Logo" },
    { src: leonardo, alt: "Leonardo Logo" },
    { src: midjourney, alt: "Midjourney Logo" },
    { src: luma, alt: "Luma Logo" },
  ];

  // const handleInputChange = (e) => {
  //   const { name, value } = e.target;
  //   setFormData({
  //     ...formData,
  //     [name]: value,
  //   });
  // };

  // const handleSubmit = async (e) => {
  //   e.preventDefault();
  //   setIsSubmitting(true);

  //   try {
  //     const response = await fetch(
  //       "https://qolaba-server-app-security.up.railway.app/api/v1/qarrom/contactFrom",
  //       {
  //         method: "POST",
  //         headers: {
  //           "Content-Type": "application/json",
  //         },
  //         body: JSON.stringify(formData),
  //       }
  //     );

  //     if (response.ok) {
  //       setSubmitSuccess(true);
  //       setFormData({
  //         Name: "",
  //         Email: "",
  //         Phone: "",
  //         CompanyWebsite: "",
  //         Message: "",
  //       });
  //     } else {
  //       throw new Error("Failed to submit form");
  //     }
  //   } catch (error) {
  //     console.error("Error submitting form:", error);
  //   } finally {
  //     setIsSubmitting(false);
  //   }
  // };

  return (
    <div
      className="flex flex-col items-center justify-between min-h-screen px-4 py-10 bg-cover text-white"
      style={{ backgroundImage: `url(${backgroundImage})` }}
    >
      {/* Top Announcement Bar */}

      {/* Logo Carousel - Full Width with Center Alignment */}
      <div className="w-full overflow-hidden mb-10">
        <div
          className="flex justify-center items-center gap-6"
          ref={carouselRef}
          style={{
            whiteSpace: "nowrap",
            overflowX: "auto",
            scrollbarWidth: "none",
            msOverflowStyle: "none", 
          }}
        >
          {carouselImages.map((image, index) => (
            <img
              key={index}
              src={image.src}
              alt={image.alt}
              className="w-24 h-24 object-contain mx-4"
              style={{ flexShrink: 0, minWidth: "6rem", minHeight: "6rem" }} // Prevent shrinking
            />
          ))}
        </div>
      </div>

     
      {/* Call to Action */}
      <div className="text-center mb-10">
        <h2 className="text-4xl md:text-5xl font-bold mb-4">
          Get Started with AI Magic.
        </h2>
        <p className="text-lg md:text-xl text-gray-300 mb-6">
          Let Qarrom AI take your content to the next level with our game-changing QCAAS model. Contact us to start your journey today.
        </p>
        <button
          className="px-8 py-3 rounded-full bg-orange-500 hover:bg-orange-600 text-white font-semibold text-lg transition"
          onClick={() => {
            window.scrollTo({ 
              top: document.body.scrollHeight, 
              behavior: 'smooth' 
            }); 
          }}
        >
          Lets Talk
        </button>
      </div>
 {/* Form Section
 <div className="w-full max-w-lg mx-auto bg-transparent p-6 rounded-lg shadow-lg mb-8">
  <h3 className="text-xl font-bold mb-4">Contact Us</h3>
  <form onSubmit={handleSubmit}>
    <div className="mb-4">
      <input
        type="text"
        id="Name"
        name="Name"
        value={formData.Name}
        onChange={handleInputChange}
        placeholder="Your Name"
        className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-orange-500"
        required
      />
    </div>

    <div className="mb-4">
      <input
        type="email"
        id="Email"
        name="Email"
        value={formData.Email}
        onChange={handleInputChange}
        placeholder="Your Email"
        className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-orange-500"
        required
      />
    </div>

    <div className="mb-4">
      <input
        type="tel"
        id="Phone"
        name="Phone"
        value={formData.Phone}
        onChange={handleInputChange}
        placeholder="Your Phone"
        className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-orange-500"
        required
      />
    </div>

    <div className="mb-4">
      <input
        type="url"
        id="CompanyWebsite"
        name="CompanyWebsite"
        value={formData.CompanyWebsite}
        onChange={handleInputChange}
        placeholder="Company Website (optional)"
        className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-orange-500"
      />
    </div>

    <div className="mb-4">
      <textarea
        id="Message"
        name="Message"
        value={formData.Message}
        onChange={handleInputChange}
        placeholder="Your Message"
        className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-orange-500"
        rows="4"
        required
      />
    </div>

    <button
      type="submit"
      className={`w-full py-2 bg-orange-500 text-white font-semibold rounded-full transition ${
        isSubmitting ? "opacity-50 cursor-not-allowed" : "hover:bg-orange-600"
      }`}
      disabled={isSubmitting}
    >
      {isSubmitting ? "Submitting..." : "Submit"}
    </button>
  </form>
  {submitSuccess && (
    <div className="mt-4 text-green-500 text-center">Thank you for your message! We will get back to you soon.</div>
  )}
</div> */}

    <ContactForm className='' />

      {/* Footer */}
      <div className="text-center mt-4 text-xs text-gray-400">
        Powered by Qarrom AI. ©2024 Qarrom AI. All Rights Reserved.
      </div>
    </div>
  );
};
