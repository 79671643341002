import React from "react"; 
export const PricingSection = ({ showForm, setShowForm }) => {
  return (
    <div
      className="flex flex-col items-center justify-center min-h-screen px-4 py-10 bg-cover text-white"
     
    >
      {/* Header */}
      <div className="text-center mb-10">
        <h2 className="text-4xl md:text-5xl font-bold mb-4">Our Pricing Plans</h2>
        <p className="text-lg md:text-xl text-gray-300">
        Choose from flexible packages designed to suit your content needs and budget
        </p>
      </div>

      {/* Pricing Cards Section */}
      <div className="flex flex-col md:flex-row items-stretch justify-center gap-6 max-w-6xl w-full mt-12">
        
        {/* Standard Plan Card */}
        <div className="relative flex flex-col items-center bg-white bg-opacity-10 p-8 rounded-lg border border-blue-500 w-full md:w-1/3 flex-1 min-h-[500px]">
          <h3 className="text-lg font-semibold text-blue-400 mb-4"> Content Creation Retainer</h3>
          <div className="text-4xl font-bold mb-2">
          <span className="text-lg font-normal">Starting at<br/></span> &#8377;39,999<span className="text-lg font-normal">/Month</span>
          </div>
          <div className="text-gray-300 mb-6 mt-4 text-left w-full pl-4">
            Receive high-quality, AI-powered content for your brand. Perfect for businesses looking to scale up with our QCAAS model.
          </div>
          <div className="flex-grow"></div> {/* Spacer to push button to bottom */}
          <button className="w-full py-3 rounded-full bg-blue-500 hover:bg-blue-600 transition"   onClick={() => {
    window.scrollTo({ 
      top: document.body.scrollHeight, 
      behavior: 'smooth' 
    }); 
  }}>
            Lets Talk 
          </button>
        </div>

        {/* Professional Plan Card */}
        <div className="relative flex flex-col items-center bg-white bg-opacity-10 p-8 rounded-lg border border-orange-500 w-full md:w-1/3 flex-1 min-h-[500px]">
          <h3 className="text-lg font-semibold text-orange-400 mb-4"> Learn AI</h3>
          <div className="text-4xl font-bold mb-2">
          <span className="text-lg font-normal">Starting at<br/></span> 
          &#8377;19,999<span className="text-lg font-normal">/Month</span>
          </div>

          {/* Badge */}
          <div className="absolute top-[-14px] right-4 bg-orange-500 text-white px-3 py-1 rounded-full text-sm">
          Most Popular
          </div>

          <div className="text-gray-300 mb-6 text-left mt-4 w-full pl-4">
          In-depth, live AI training sessions tailored for your entire team. Empower your team to create AI-enhanced content independently

          </div>
          <div className="flex-grow"></div> {/* Spacer to push button to bottom */}
          <button className="w-full py-3 rounded-full bg-orange-500 hover:bg-orange-600 transition"     onClick={() => {
    window.scrollTo({ 
      top: document.body.scrollHeight, 
      behavior: 'smooth' 
    }); 
  }}>
            Lets Talk  
          </button>
        </div>

        {/* Book Call Plan Card */}
        <div className="relative flex flex-col items-center bg-white bg-opacity-10 p-8 rounded-lg border border-gray-500 w-full md:w-1/3 flex-1 min-h-[500px]">
          <h3 className="text-lg font-semibold text-gray-400 mb-4"> 3-Month Hybrid Package</h3>
          <div className="text-4xl font-bold mb-2">
          <span className="text-lg font-normal">Starting at<br/></span> &#8377; 29,999
          </div>
          <p className="text-gray-300 mb-6 text-left mt-4 w-full">
          A comprehensive 3-month package combining AI-powered content services with hands-on training for long-term success. Ideal for brands aiming to leverage AI in content creation and skill-building.
          </p>
          <div className="flex-grow"></div> {/* Spacer to push button to bottom */}
          <button className="w-full py-3 rounded-full bg-gray-500 hover:bg-gray-600 transition"     onClick={() => {
    window.scrollTo({ 
      top: document.body.scrollHeight, 
      behavior: 'smooth' 
    }); 
  }}>
           Lets Talk 
          </button>
        </div>
      </div>
    </div>
  );
};
