import React, { useEffect, useState } from "react";
import { LandingSection } from "./LandingSection";
import { AgencySection } from "./agency";
import { PastVideoSection } from "./past_video";
import { PastExtraSection } from "./extra_service";
import { HowItWorks } from "./how";
import { PricingSection } from "./pricing";
import { FAQSection } from "./faq";
import GetStartedForm from "./GetStartedForm";
import { PastWorkSection } from "./past";
import { FooterSection } from "./footer";

const Home = () => {
  const [showForm, setShowForm] = useState(false);

  useEffect(() => {
    if (showForm) {
      document.body.style.overflow = "hidden"; // Prevent body scroll when modal is open
    } else {
      document.body.style.overflow = "auto"; // Restore body scroll when modal is closed
    }
  }, [showForm]);
  return (
    <>
      <LandingSection
        id="landing"
        showForm={showForm}
        setShowForm={setShowForm}
      />
      <AgencySection
        id="benefits"
        showForm={showForm}
        setShowForm={setShowForm}
      />
      <PastVideoSection
        id="past-video"
        showForm={showForm}
        setShowForm={setShowForm}
      />
      <PastWorkSection
        id="past-work"
        showForm={showForm}
        setShowForm={setShowForm}
      />
      <PastExtraSection
        id="past-extra"
        showForm={showForm}
        setShowForm={setShowForm}
      />
      <HowItWorks
        id="how-it-works"
        showForm={showForm}
        setShowForm={setShowForm}
      />
      <PricingSection
        id="pricing"
        showForm={showForm}
        setShowForm={setShowForm}
      />
      <FAQSection id="faq" showForm={showForm} setShowForm={setShowForm} />
      <FooterSection
        id="footer"
        showForm={showForm}
        setShowForm={setShowForm}
      />
      {showForm && <GetStartedForm onClose={() => setShowForm(false)} />}
    </>
  );
};

export default Home;
