// App.js
import "./App.css";
import React from "react";
import { Route, Routes } from "react-router-dom";
import Home from "./Home";
import { Thankyou } from "./thankyou";

function App() {
  return (
    // <div className="App">
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/thankyou" element={<Thankyou />} />
      </Routes>
    // </div>
  );
}

export default App;
